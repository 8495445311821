<i18n>
ru:
  birthday: Дата рождения
  city: Адрес точки
  coverLetter: Сопроводительное письмо
  curriculumVitae: Файл с резюме
  email: E-mail
  name: ФИО
  personalDataMessage: 'Предоставляя свои персональные данные, Вы соглашаетесь с '
  personalDataMessageLink: условиями обработки персональных данных
  phoneNumber: Телефон
  send: Отправить анкету
  successMessage: Анкета успешно отправлена
  textOverSendButton: ''
  vacancy: Вакансия
ua:
  birthday: Дата народження
  city: Адреса точки
  coverLetter: Супровідний лист
  curriculumVitae: Файл з резюме
  email: E-mail
  name: ПІБ
  personalDataMessage: 'Надаючи свої персональні дані, ви погоджуєтеся з '
  personalDataMessageLink: умовами обробки персональних даних
  phoneNumber: Телефон
  send: Надіслати анкету
  successMessage: Анкета успішно відправлена
  textOverSendButton: ''
  vacancy: Вакансія
us:
  birthday: Date of birth
  city: City
  coverLetter: Cover letter
  curriculumVitae: Curriculum vitae
  email: E-mail
  name: Full name
  personalDataMessage: 'By submitting your personal data, you agree to '
  personalDataMessageLink: the terms of personal data processing
  phoneNumber: Phone
  send: Submit application
  successMessage: Form submitted successfully
  textOverSendButton: ''
  vacancy: Vacancy
</i18n>

<template>
  <div
    id="v-vacancy-form"
    class="v-vacancy-form"
  >
    <div class="v-row">
      <div
        class="v-col-12 v-col-md-10 v-offset-md-1 v-col-xl-8 v-offset-xl-2 v-mb-md v-py-sm v-vacancy-form--title v-title"
        v-html="translate('vacanciesList.joinOurTeam')"
      />
      <div
        class="v-col-12 v-col-md-5 v-col-xl-4 v-offset-md-1 v-offset-xl-2"
        v-form-validator="{
          Form: validatorForm,
          Value: vacancyFields.vacancyId,
          Required: true,
          Validations: ['select']
        }"
      >
        <arora-select
          required
          :label="translate('vacanciesForm.vacancy')"
          :options="pageStore.Vacancies.map((item) => item.ID)"
          v-model:selected="vacancyFields.vacancyId"
        >
          <template #index="options: { index: number }">
            <option
              :value="pageStore.Vacancies[options.index].ID"
              v-html="sanitize(pageStore.Vacancies[options.index].Title)"
            />
          </template>
        </arora-select>
      </div>
      <div
        class="v-col-12 v-col-md-5 v-col-xl-4"
        v-form-validator="{
          Form: validatorForm,
          Value: vacancyFields.wishfulTerminalName,
          Required: true,
          Validations: ['select']
        }"
      >
        <arora-select
          required
          :label="translate('vacanciesForm.city')"
          :options="terminals.map((item) => item.ID)"
          v-model:selected="vacancyFields.wishfulTerminalName"
        >
          <template #index="options: { index: number }">
            <option
              :value="terminals[options.index].ID"
              v-html="
                sanitize(
                  allTerminalsAreInOneCity
                    ? terminals[options.index].Address
                    : `${translate('selfServiceTerminals.cityShort', { city: terminals[options.index].City })}, ${sanitize(
                        terminals[options.index].Address
                      )}`
                )
              "
            />
          </template>
        </arora-select>
      </div>
      <div class="v-col-12 v-col-md-5 v-col-xl-4 v-offset-md-1 v-offset-xl-2">
        <v-input
          class-name="v-arora-input"
          required
          v-form-validator="{
            Form: validatorForm,
            Value: vacancyFields.name,
            Required: true,
            Validations: ['text', 'length'],
            MaxLength: 255
          }"
          :label="translate('vacanciesForm.name')"
          v-model:text="vacancyFields.name"
        />
      </div>
      <div class="v-col-12 v-col-md-5 v-col-xl-4">
        <arora-date-input
          v-form-validator="{
            Form: validatorForm,
            Value: vacancyFields.bday,
            Required: appConfig.VueSettingsPreRun.VacanciesRequiredToFillBirthday,
            Validations: ['birthday']
          }"
          :label="translate('vacanciesForm.birthday')"
          :required="appConfig.VueSettingsPreRun.VacanciesRequiredToFillBirthday"
          v-model:text="vacancyFields.bday"
        />
      </div>
      <div class="v-col-12 v-col-md-5 v-col-xl-4 v-offset-md-1 v-offset-xl-2">
        <common-phone-input
          required
          :label="translate('vacanciesForm.phoneNumber')"
          :validator-form="validatorForm"
          v-model:phone="vacancyFields.phone"
        />
      </div>
      <div class="v-col-12 v-col-md-5 v-col-xl-4">
        <v-email-input
          class-name="v-arora-input"
          v-form-validator="{
            Form: validatorForm,
            Value: vacancyFields.email,
            Required: appConfig.VueSettingsPreRun.VacanciesRequiredToFillEmail,
            Validations: ['email', 'length'],
            MaxLength: 64
          }"
          :label="translate('vacanciesForm.email')"
          :required="appConfig.VueSettingsPreRun.VacanciesRequiredToFillEmail"
          v-model:text="vacancyFields.email"
        />
      </div>
      <div class="v-col-12 v-col-md-10 v-offset-md-1 v-col-xl-8 v-offset-xl-2">
        <arora-textarea
          v-form-validator="{
            Form: validatorForm,
            Value: vacancyFields.about,
            Required: appConfig.VueSettingsPreRun.VacanciesRequiredToFillCoverLetter,
            Validations: []
          }"
          :label="translate('vacanciesForm.coverLetter')"
          :required="appConfig.VueSettingsPreRun.VacanciesRequiredToFillCoverLetter"
          :textarea-rows="2"
          v-model:text="vacancyFields.about"
        />
      </div>
      <div class="v-col-12 v-col-md-10 v-offset-md-1 v-col-xl-8 v-offset-xl-2">
        <arora-file-input
          v-form-validator="{
            Form: validatorForm,
            Value: vacancyFields.attachment ? '#' : null,
            Required: appConfig.VueSettingsPreRun.VacanciesRequiredToFillCurriculumVitae,
            Validations: []
          }"
          :allowed-types="[MIME.pdf, MIME.doc, MIME.docx, MIME.ppt, MIME.pptx]"
          :label="translate('vacanciesForm.curriculumVitae')"
          :max-bytes="maxBytes"
          :required="appConfig.VueSettingsPreRun.VacanciesRequiredToFillCurriculumVitae"
          v-model:file="vacancyFields.attachment"
        />
      </div>

      <div class="v-col-12 v-col-md-10 v-offset-md-1 v-col-xl-8 v-offset-xl-2 v-mb-xs">
        <lazy-common-captcha />
      </div>

      <div
        v-if="!stringIsNullOrWhitespace(translate('vacanciesForm.textOverSendButton'))"
        class="v-col-12 v-col-md-10 v-offset-md-1 v-col-xl-8 v-offset-xl-2 v-mb-xs"
        v-html="translate('vacanciesForm.textOverSendButton')"
      />
      <transition
        appear
        mode="out-in"
        name="fade"
      >
        <div
          v-if="error"
          class="v-col-12 v-col-md-10 v-offset-md-1 v-col-xl-8 v-offset-xl-2 v-mb-xs v-error-color"
          v-html="error"
        />
      </transition>

      <div
        v-if="appConfig.VueSettingsPreRun.EnablePersonalDataWarning"
        class="v-col-12 v-col-md-10 v-offset-md-1 v-col-xl-8 v-offset-xl-2 v-mb-md"
      >
        <span
          class="v-opacity-75 v-mr-xxs"
          v-html="translate('vacanciesForm.personalDataMessage')"
        />
        <arora-nuxt-link
          open-in-new-tab
          :href="appConfig.VueSettingsPreRun.Links.PersonalDataLink"
          :label="translate('vacanciesForm.personalDataMessageLink')"
        />
      </div>

      <div
        class="v-col-12 v-col-md-10 v-offset-md-1 v-col-xl-8 v-offset-xl-2 v-d-flex v-justify-content-end"
      >
        <arora-button
          :disabled="lockButton"
          :label="translate('vacanciesForm.send')"
          @click="async () => await send()"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Terminal } from '~types/addressStore'
import type { VacancyPayload } from '~types/pageStore'

import { MIME, useCommon, useValidationStore, VEmailInput, vFormValidator, VInput } from '@arora/common'

import { getActivePinia } from 'pinia'
import { AuthOperationsErrorCode, Guid } from '~api/consts'

const restaurantStore = useRestaurantStore()
const accountStore = useAccountStore()
const addressStore = useAddressStore()
const pageStore = usePageStore()
const popupStore = usePopupStore()
const validationStore = useValidationStore(getActivePinia())

const appConfig = useAppConfig()
const { sanitize, translate } = useI18nSanitized()
const { stringIsNullOrWhitespace } = useCommon()
const { eventEmit } = useEmitter()

const terminals = computed<Terminal[]>(() =>
  (addressStore.Terminals?.data ?? [])
    .filter((t: Terminal) => t.Active && !t.HideInVacancySelect)
    .sort((a: Terminal, b: Terminal) => {
      return a.Address > b.Address ? 1 : -1 //sort alphabetically
    })
)

const allTerminalsAreInOneCity = computed<boolean>(() =>
  terminals.value.every((terminal: Terminal) => terminal.City === terminals.value[0].City)
)

const maxBytes = 200 * 1024

const vacancyFields = ref<VacancyPayload>({
  about: undefined as string | undefined,
  attachment: null as File | null,
  bday: null as string | null,
  citizenship: null as string | null,
  email: undefined as string | undefined,
  name: undefined as string | undefined,
  passedV3: true,
  phone: undefined as string | undefined,
  recaptcha: null as string | null,
  smartcaptcha: null as string | null,
  vacancyId: pageStore.SelectedVacancyId ?? Guid.Empty,
  wishfulTerminalName: undefined as string | undefined
})
onMounted(() => {
  if (import.meta.client)
    addressStore
      .initTerminals()
      .then(
        () =>
          (vacancyFields.value.wishfulTerminalName =
            terminals.value.length > 0 ? terminals.value[0].ID : undefined)
      )

  if (accountStore.isLoggedIn && accountStore.Profile.data) {
    const profileData = accountStore.Profile.data

    vacancyFields.value.name = profileData.Name
    vacancyFields.value.email = profileData.Email
    vacancyFields.value.phone = profileData.Phone
  }
})

const validatorForm = 'vacancy'

const error = ref<string | null>(null)
const lockButton = ref<boolean>(false)

async function send(): Promise<void> {
  lockButton.value = true
  error.value = null

  vacancyFields.value = await restaurantStore.validateCaptcha(vacancyFields.value)

  if (!validationStore.formPassedCheck(validatorForm)) {
    lockButton.value = false

    return
  }
  const code = await pageStore.sendVacancy(vacancyFields.value)
  lockButton.value = false

  switch (code) {
    case 0: {
      await popupStore.showSuccess(translate('vacanciesForm.successMessage'))

      return
    }
    case AuthOperationsErrorCode.CaptchaWasNotVerified:
    case AuthOperationsErrorCode.CaptchaV3Failed: {
      error.value = translate('accountManagement.captchaValidationError')
      break
    }
    default: {
      error.value = translate('feedbackPopup.ajaxErrorUnknown')

      return
    }
  }

  eventEmit('v-reset-captcha')
}

watch(
  () => pageStore.SelectedVacancyId,
  (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      vacancyFields.value.vacancyId = newValue
    }
  }
)
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-vacancy-form {
  display: flex;
  background: variables.$BodyElementsBackground;
  border-radius: variables.$BorderRadius;
  box-shadow: variables.$CardShadow;
  padding: 1.5rem;

  &--title {
    font-weight: 600;
    font-size: variables.$TextSizeH2;
  }

  @include mixins.sm {
    padding: 1rem;
  }
}
</style>
